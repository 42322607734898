<template>
  <div class="mt-1 mx-3">
    <cooperators> </cooperators>
  </div>
</template>

<script>
import Cooperators from "@/components/profile/cooperators/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Cooperators,
  },
  created() {
    this.notifyQueue({
      text: "Profilo",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Collaborazioni orizzontali",
      path: "brokers.index1",
      level: 2,
    });
  },
};
</script>
